.minimap-section-container {
  text-align: left;
  
  .section-text {
    font-size: 0.75rem;
    font-weight: 600;
    color: #333;
  }

  &:not(:last-of-type) {
    margin-bottom: 1.75vh;
  }
}