.button, .button--filled {
  border: 1px solid #b98d58;
  border-radius: 50px;
  box-sizing: border-box;
  font-weight: 600;
  display: block;
  cursor: pointer;

  transition: 0.25s ease-in-out;

  &.xs {
    padding: 0.15rem;
    font-size: 0.65rem;
    font-weight: 400;
  }

  &.sm {
    padding: 0.325rem;
    font-size: 0.825rem;
  }

  &.md {
    padding: 0.5rem;
    font-size: 0.9rem;
  }

  &.lg {
    padding: 0.675rem;
    font-size: 1.025rem;
  }

  &.xl {
    padding: 0.85rem;
    font-size: 1.15rem;
  }
}

.button {
  background-color: #fff;
  color: #545D5C;

  &.alternative {
    color: #B98D58;
    border: none;
  }
  
  &:hover {
    background-color: #f5f5f5;
  }
}

.button--filled {
  background-color: #B98D58;
  color: #fff;

  &:hover {
    background-color: #b16f1d;
  }
}