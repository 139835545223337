.overview-sidebar {
  width: 22.5vw;
  min-width: 275px;
  max-width: 450px;
  box-sizing: border-box;
  padding: 1.5vw;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}