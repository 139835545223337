.minimap-leather-section {
  position: relative;
  width: 100%;
  border: 1px solid grey;
  height: 15vh;
  box-sizing: border-box;

  .section-template-image {
    width: 100%;
    height: 100%;

    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }

  .section-image-wrapper {
    position: absolute;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);
    overflow: hidden;


    img {
      position: absolute;
      display: block;
      max-height: 100%;
      max-width: 100%;
      left: 0;
      top: 0;

      pointer-events: none;
      -webkit-user-drag: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
    }
  }
}