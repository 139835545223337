@keyframes dotty {
  0%   { content: ''; }
  25%  { content: '.'; }
  50%  { content: '..'; }
  75%  { content: '...'; }
  100% { content: ''; }
}

.controls-sidebar {
  width: 22.5vw;
  min-width: 275px;
  max-width: 450px;
  box-sizing: border-box;
  padding: 1.5vw;
  height: 100vh;

  button {
    width: 100%;
    margin-bottom: 1rem;
  }

  .gallery-section {
    height: 120vh;
    overflow: auto;

    h2 {
      text-align: left;
      font-size: 1.5rem;
      margin: unset;
      margin-bottom: .5rem;
      font-weight: 600;
      color: #545D5C;
    }

    &__add-image {
      margin-bottom: .5rem;
    }

    .loading-text::after {
      display: inline-block;
      animation: dotty steps(1,end) 1s infinite;
      content: '';
    }
  }
}