.album-information-container {
  height: fit-content;
  box-sizing: border-box;
  padding: 0.5rem;
  border: 1px solid #B98D58;
  border-radius: 12px;
  text-align: left;

  h4 {
    font-weight: 600;
    font-size: 0.9rem;
    color: #545D5C;
    text-align: center;
    margin-bottom: 0.75rem;
  }

  p {
    font-size: 0.75rem;
    color: #545D5C;

    &.golden {
      color: #B98D58;
    }

    &:not(:last-of-type) {
      margin-bottom: 0.25rem;
    }
  }
}