.toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #B98D58;
  border-radius: 24px;
  overflow: hidden;

  &__option, &__option--focused {
    width: 50%;
    background-color: #fff;
    font-size: 0.85rem;
    box-sizing: border-box;
    font-weight: 500;
    padding: 0.5rem 0.35rem;
    user-select: none;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  &__option {
    cursor: pointer;
    color: #B98D58;
  }

  &__option--focused {
    background-color: #B98D58;
    color: #fff;
    cursor: default;
  }
}