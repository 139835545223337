.minimap-section {
  position: relative;
  width: 100%;
  height: 15vh;
  overflow: hidden;

  .section-template-image {
    width: 100%;
    height: 100%;

    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
}