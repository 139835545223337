.image-section-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  img {
    display: block;
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    top: 0;
    left: 0;

    pointer-events: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
}