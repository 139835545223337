.user-input-image-container {
  position: absolute;
  overflow: hidden;
  background-color: #fff;

  .section-image-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;

    img {
      position: absolute;
      display: block;
      max-height: 100%;
      max-width: 100%;
      left: 0;
      top: 0;

      pointer-events: none;
      -webkit-user-drag: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
    }
  }
}