.uploaded-gallery-image {
  position: relative;

  .floating-remove-button {
    width: 1.25rem;
    height: 1.25rem;
    position: absolute;
    top: 2.5%;
    right: 2.5%;
    z-index: 10000000;
    overflow: hidden;

    svg {
      width: 0.75rem!important;
      height: 0.75rem!important;
      margin-bottom: -0.115rem!important;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}