.floating-edit-button {
  width: fit-content;
  height: fit-content;
  background: none;
  border: none;
  outline: none;
  padding: unset;
  margin: unset;
  cursor: pointer;


  &:hover {
    svg path {
      opacity: 0.75;
    }
  }
}