.user-editable-text-region-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  overflow: hidden;
  display: flex;
  flex-direction: column;;
  justify-content: center;
  white-space: pre-wrap;

  cursor: text!important;

  &__user-editable-text-region {
    display: inline-block;
    cursor: text!important;

    &:focus {
      outline: 0px solid transparent;
    }
  }
}