.user-input-image-container {
  position: absolute;
  overflow: hidden;
  background-color: transparent;

  .placeholder {
    border: none;
    position: absolute;
    object-fit: contain;
    width: 40%;
    height: 40%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.shadow-container {
  position: absolute;
  background-color: transparent;
  overflow: hidden;
}