.container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;

  &__content {
    h1 {
      font-size: 2.5vh;
      color: #212121;
      margin-bottom: 0.75rem;
    }

    p {
      color: #333;
      font-size: 1.75vh;
    }
  }
}