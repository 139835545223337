.floating-remove-button {
  width: fit-content;
  height: fit-content;
  background: none;
  border: none;
  outline: none;
  padding: unset;
  margin: unset;
  cursor: pointer;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 50%;
  padding: 0.25rem;
  width: 2.05rem;
  height: 2.05rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #333;

  svg {
    fill: #545454;
    width: 1rem;
    height: 1rem;
    margin-bottom: -3px;
  }

  &:hover {
    opacity: 0.75;
  }
}