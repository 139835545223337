.total-price-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #333;
  border-radius: 4px;
  margin: 1.5rem 0;
  box-sizing: border-box;
  padding: 0.5rem;
  overflow-y: hidden;

  &__subtotal-price {
    font-weight: 600;
    font-size: 0.9rem;
    color: #333;
  }

  &__subtotal-details {
    font-size: 0.85rem;
    color: #0f0f0f;
  }

  &__entries {
    border: 1px solid #333;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0.5rem;
    overflow-y: auto;
    height: 90%;

    &__entry {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #333;

      &:not(:last-of-type) {
        margin-bottom: .75rem;
      }

      span {
        color: #545D5C;
        font-size: 0.9rem;

        &:first-of-type {
          font-weight: 600;
        }
      }
    }
  }

  &__total-price {
    color: #545D5C;
    font-weight: 600;
  }
}