.layout {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;

  .content-container {
    display: flex;
    flex-direction: column;
    width: 500px;

    .entry-content {
      h2, p {
        color: #545D5C;
      }

      h2 {
        font-size: 1.35rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }

      p {
        font-size: 0.85rem;
      }
    }

    .album-creation-form-wrapper {
      height: auto;
      border-radius: 12px;
      box-sizing: border-box;
      padding: 5rem;
      background: #F9F8F4;
      border: 1px solid #B98D58;
      margin: .75rem 0;

      &__header {
        margin-bottom: 2.5rem;

        &__title {
          font-size: 1.75rem;
          font-family: Poppins;
          font-weight: 700;
          letter-spacing: 0em;
          text-align: center;
          color: #545D5C;
          text-transform: uppercase;
        }

        &__brand {
          color: #B98D58;
          font-weight: 400;
          font-size: 1.1rem;
        }
      }

      &__album-creation-form {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .field-wrapper {
          width: 100%;
          text-align: left;
          display: flex;
          flex-direction: column;
          margin-bottom: 1.25rem;

          .album-type-select {
            border: 1px solid #B98D58;
            background-color: #fff;
            display: flex!important;
            justify-content: center!important;
            align-items: center!important;
            border-radius: 50px;
            text-align: center;
            height: 2.475rem;

            &.no-value input {
              flex: 1;
              font-size: 0.9rem;
              text-align: center;
            }

            span {
              align-self: center;
              justify-self: center;
              flex: 1;
            }

            span[role="option"] {
              width: 100%;
            }
          }

          label {
            font-weight: 600;
            font-size: 0.75rem;
            margin-bottom: 0.15rem;
          }
        }
      }
    }

    .website-link {
      font-size: 0.85rem;
      color: #545D5C;
    }
  }
}

.overlay {
  background-color: #fff;
  position: fixed;
  z-index: 100000000;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-container {
  z-index: 100000001;
}