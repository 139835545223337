.navbar {
  background-color: #545D5C;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0.75rem 1.5rem;

  &__left {
    &__title {
      font-size: 1.25rem;
      color: #fff;
      font-weight: 600;
    }
  }

  &__right {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    button {
      width: 8rem;

      &:not(:first-of-type) {
        margin-left: 0.75rem;
      }
    }
  }
}