.user-editable-text-region-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  overflow: hidden;
  cursor: text!important;
  display: flex;
  flex-direction: column;;
  justify-content: center;
  white-space: pre-line;

  &__user-editable-text-region {
    display: inline-block;
  }
}