.input {
  font-family: Poppins;
  font-weight: 500;
  text-align: center;
  border: 1px solid #B98D58;
  background-color: #fff;
  border-radius: 50px;
  display: block;
  outline-color: #B98D58;

  input:-webkit-autofill { 
    background-clip: text;
  }

  &:focus {
    outline-width: 2px;
  }

  transition: 0.25s ease-in-out;

  &.xs {
    padding: 0.15rem;
    font-size: 0.65rem;
    height: 0.9rem;
    font-weight: 400;
  }

  &.sm {
    padding: 0.325rem;
    height: 1.15rem;
    font-size: 0.825rem;
  }

  &.md {
    padding: 0.5rem;
    height: 1.35rem;
    font-size: 0.9rem;
  }

  &.lg {
    padding: 0.675rem;
    height: 1.55rem;
    font-size: 1.025rem;
  }

  &.xl {
    padding: 0.85rem;
    height: 1.75rem;
    font-size: 1.15rem;
  }
}