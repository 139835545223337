@import '../../../../public/styles/variables.scss';

.content-editing-area {
  width: 100%;
  min-height: calc(100vh - 60px);
  height: calc(100vh - 60px);
  overflow: auto;
  background-color: #fafafa;

  .initial-message {
    margin-top: 2.5vh;
    font-size: 0.75rem;
    color: #545D5C;
  }

  .album-covers-sections-container {
    width: 95%;
    margin: 5vh auto 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1200px;
  }

  .album-content-sections-container {
    width: 95%;
    margin: 5vh auto 0 auto;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .add-section-btn {
    background-color: $background-color-alternative;
    box-sizing: border-box;
    padding: .35rem 0.75rem;
    color: $font-color-alternative;
    border-radius: 16px;
    transition: all 0.25s ease-in-out;
    font-weight: 500;
    border: 1px solid $background-color-alternative;
    margin-bottom: 5vh;
    cursor: pointer;

    &:hover {
      background-color: #4f4f4f;
    }
  }
}