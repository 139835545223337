.gallery {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 0.5rem;
  column-gap: 0.5rem;
  max-height: 65vh;
  overflow-y: auto;

  .draggable-block {
    height: 100px;
    width: 200px;
  }
}